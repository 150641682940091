export default (data = {}) => {
    return {
        event: {
            account:null,
            category: "",
            client: "",
            code: null,
            color: "",
            end: null,
            name: "",
            start: null,
            timed: true,
        },
        eventParsed: {
            start: { date: null, time: null },
            end: { date: null, time: null }
        },
        ...data
    }
}

