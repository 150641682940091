<template>
  <v-container>
    <v-card elevation-10 width="100%" class="mt-6">
      <v-card-title>
        <v-select
          id="category"
          hide-details="auto"
          label="Client"
          :items="clients"
          item-text="name"
          item-value="code"
          outlined
          required
          return-object
          @change="client_selection"
        />
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text v-if="clientSelected.name">
        <h3>{{ this.clientSelected.name }}</h3>
        {{ this.clientSelected.address }} - {{ this.clientSelected.address2 }} ,
        {{ this.clientSelected.postal_code }}<br />
        {{ this.clientSelected.mobile }}
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>

    <v-card elevation-10 width="100%" class="mt-6" v-if="clientSelected.code">
      <v-data-table
        :headers="headers[$vuetify.breakpoint.xsOnly]"
        :items="team"
        :items-per-page="10"
        item-key="code"
        sort-by="issue_date"
        mobile-breakpoint="10"
        loader-height="10"
        loading-text="Cargando ..."
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Our team</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="#1c2e5a"
              @click="addMember = !addMember"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <!-- <template v-slot:[`item.picture`]="{ item }">
          <v-list-item-avatar>
            <v-img contain :src="getImagePhoto(item.account, item.picture)"></v-img>
          </v-list-item-avatar>
        </template> -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="delete_member(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-sheet tile class="d-flex mt-6" v-if="clientSelected.code">
      <v-toolbar flat>
        <v-btn icon @click="$refs.calendar.prev()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-btn icon @click="$refs.calendar.next()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn icon color="success" class="ma-2" @click="editable = !editable">
          <v-icon v-if="!editable">mdi-lock-outline</v-icon>
          <v-icon v-else>mdi-lock-open-outline</v-icon>
        </v-btn>

        <v-btn icon color="success" class="ma-2" @click="update_calendar">
          <v-icon>mdi-content-save-outline</v-icon>
        </v-btn>

        <!-- <v-btn
          fab
          small
          color="success"
          class="ma-2"
          @click="event_dialog = !event_dialog"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn> -->

        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Day</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Week</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Month</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = '4day'">
              <v-list-item-title>4 days</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'category'">
              <v-list-item-title>Category</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>

    <v-sheet tile v-if="clientSelected.code">
      <v-calendar
        ref="calendar"
        v-model="value"
        color="primary"
        :type="type"
        :events="events"
        :event-color="getEventColor"
        :event-ripple="false"
        @click:day="changeToWeek"
        @mousedown:event="startDrag"
        @mousedown:time="startTime"
        @mousemove:time="mouseMove"
        @mouseup:time="endDrag"
        @mouseleave.native="cancelDrag"
        @click:event="showEvent"
        dense
      >
        <template v-slot:event="{ event, timed, eventSummary, eventParsed }">
          <div>
            {{ eventParsed.start.time + " - " + eventParsed.end.time }}
          </div>
          <div class="v-event-draggable">
            <component :is="{ render: eventSummary }"></component>
          </div>
          <div
            v-if="timed"
            class="v-event-drag-bottom"
            @mousedown.stop="extendBottom(event)"
          ></div>
        </template>
      </v-calendar>
    </v-sheet>

    <v-navigation-drawer right :value="addMember" width="400px" app>
      <v-card>
        <v-card-title class="pa-0">
          <v-app-bar color="#1c2e5a" dark>
            <v-app-bar-title>Team</v-app-bar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="addMember = !addMember">
              <v-icon>mdi-close </v-icon>
            </v-btn>
          </v-app-bar>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers[true]"
            :items="employees"
            :items-per-page="10"
            item-key="code"
            sort-by="issue_date"
            mobile-breakpoint="10"
            loader-height="10"
            loading-text="Cargando ..."
            @click:row="addTeamMember"
          >
            <!-- <template v-slot:[`item.picture`]="{ item }">
              <v-list-item-avatar>
                <v-img contain :src="getImagePhoto(item.account, item.picture)"></v-img>
              </v-list-item-avatar>
            </template> -->
          </v-data-table>
        </v-card-text>
        <v-card-actions class="pl-0 pr-0"> </v-card-actions>
      </v-card>
    </v-navigation-drawer>

    <EventForm
      :event="event"
      :dialog="event_dialog"
      @close="
        update_calendar();
        event_dialog = false;
      "
      @delItem="delItm"
    />
  </v-container>
</template>
<script>
import { webserver, getIssueDate, getToday } from "../../services/webserver.js";
import createClient from "../../utils/createParty.js";
import createEvent from "../../utils/createEvent.js";
import EventForm from "../../components/EventForm.vue";
export default {
  components: { EventForm },
  mounted() {
    const d = new Date().getTime();
    console.log(d);
    this.get_clients();
  },
  data() {
    return {
      clients: [],
      employees: [],
      team: [],
      clientSelected: createClient(),
      evenClient: "",
      editable: false,
      addMember: false,
      event_dialog: false,
      issueDateFrom: getIssueDate(5),
      issueDateTo: getToday(),
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      headers: {
        false: [
          // {
          //   text: "",
          //   align: "start",
          //   sortable: true,
          //   value: "picture",
          // },
          {
            text: "Name",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "Address",
            align: "start",
            sortable: true,
            value: "address",
            dataType: "text",
          },
          {
            text: "City",
            align: "start",
            sortable: true,
            value: "city",
            dataType: "text",
          },
          {
            text: "Note",
            align: "start",
            sortable: true,
            value: "note",
            dataType: "text",
            width: "30%",
          },
          {
            text: "Issue Date",
            align: "start",
            sortable: true,
            value: "issue_date",
            dataType: "text",
          },
          {
            text: "Mobile",
            align: "end",
            sortable: true,
            value: "mobile",
            dataType: "text",
          },
          {
            text: "",
            align: "end",
            value: "actions",
          },
        ],
        true: [
          // {
          //   text: "",
          //   align: "start",
          //   sortable: true,
          //   value: "picture",
          // },
          {
            text: "Name",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "Mobile",
            align: "end",
            sortable: true,
            value: "mobile",
            dataType: "text",
          },
        ],
      },
      type: "week",
      types: ["month", "week", "day", "4day"],
      mode: "stack",
      modes: ["stack", "column"],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      value: "",
      events: [],
      event: createEvent(),
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
    };
  },
  methods: {
    get_clients() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "clients",
        filters: [
          { field: "account", operator: "=", value: this.$store.state.profile.account },
        ],
      };
      this.$store.dispatch("setloading", true);
      webserver("get_table_b", qry, (data) => {
        this.$store.dispatch("setloading", false);
        this.clients = data;
        this.get_employees();
      });
    },
    get_employees() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "employees",
        filters: [
          { field: "account", operator: "=", value: this.$store.state.profile.account },
        ],
      };
      this.$store.dispatch("setloading", true);
      webserver("get_table_b", qry, (data) => {
        this.$store.dispatch("setloading", false);
        this.employees = data;
      });
    },

    client_selection(e) {
      console.log(e);
      this.clientSelected = e;
      this.get_client_team();
    },
    get_client_team() {
      var qry = {
        account: this.$store.state.profile.account,
        client: this.clientSelected.code,
        filters: [],
      };
      this.$store.dispatch("setloading", true);
      webserver("get_client_team", qry, (data) => {
        data.forEach((rg) => {
          delete rg.agent_code;
        });
        console.log(data);
        this.$store.dispatch("setloading", false);
        this.team = data;
        this.get_tasks();
      });
    },
    get_tasks() {
      this.events = [];
      var qry = {
        account: this.$store.state.profile.account,
        table: "tasks",
        filters: [
          { field: "account", operator: "=", value: this.$store.state.profile.account },
          { field: "client", operator: "=", value: this.clientSelected.code },
        ],
      };
      this.$store.dispatch("setloading", true);
      webserver("get_table_b", qry, (data) => {
        console.log(data);
        data.forEach((rg) => {
          rg.start = parseInt(rg.start);
          rg.end = parseInt(rg.end);
        });
        this.$store.dispatch("setloading", false);
        this.events = data;
      });
    },
    addTeamMember(e) {
      console.log(e);
      const index = this.team.findIndex((object) => object.code === e.code);
      if (index === -1) {
        this.team.push({ ...e });
        this.update_team();
      }
    },
    delete_member(e) {
      var index = this.team.indexOf(e);
      if (index !== -1) {
        this.team.splice(index, 1);
      }
      this.update_team();
    },
    update_team() {
      var teamLink = [];
      this.team.forEach((rg) => {
        teamLink.push({
          account: this.$store.state.profile.account,
          client_code: this.clientSelected.code,
          agent_code: rg.code,
        });
      });
      var qry = {
        account: this.$store.state.profile.account,
        table: "task_link",
        client: this.clientSelected.code,
        data: teamLink,
      };
      webserver("put_table", qry, (data) => {
        console.log(data);
      });
    },
    showEvent(e) {
      if (this.editable) {
        this.event = e;
        this.event_dialog = true;
      }
    },
    getImagePhoto: function (account, src) {
      var url =
        "https://" +
        this.$store.getters.getHost +
        "/photos/" +
        account +
        "/employees/" +
        src +
        "?" +
        Date.now();
      if (this.compressed_image) url = this.compressed_image;
      console.log(url);
      return "";
    },
    startDrag({ event, timed }) {
      if (event && timed && this.editable) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);
      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;
        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse);
        this.createEvent = {
          account: this.$store.state.profile.account,
          client: this.clientSelected.code,
          code: null,
          name: `Event #${this.events.length}`,
          start: this.createStart,
          end: this.createStart,
          timed: true,
          color: this.rndElement(this.colors),
          category: "",
        };
        if (this.editable) {
          this.events.push(this.createEvent);
        }
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag() {
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }
      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 30; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
    },
    getEventColor(event) {
      const rgb = parseInt(event.color.substring(1), 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;

      return event === this.dragEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event === this.createEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event.color;
    },
    // getEvents({ start, end }) {
    //   const events = [];
    //   console.log(start, end);
    //   // const min = new Date(`${start.date}T00:00:00`).getTime();
    //   // const max = new Date(`${end.date}T23:59:59`).getTime();
    //   // const days = (max - min) / 86400000;
    //   // const eventCount = this.rnd(days, days + 20);

    //   // for (let i = 0; i < eventCount; i++) {
    //   //   const timed = this.rnd(0, 3) !== 0;
    //   //   const firstTimestamp = this.rnd(min, max);
    //   //   const secondTimestamp = this.rnd(2, timed ? 8 : 288) * 900000;
    //   //   const start = firstTimestamp - (firstTimestamp % 900000);
    //   //   const end = start + secondTimestamp;

    //   //   events.push({
    //   //     name: this.rndElement(this.names),
    //   //     color: this.rndElement(this.colors),
    //   //     start,
    //   //     end,
    //   //     timed,
    //   //   });
    //   // }

    //   this.events = events;
    // },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    delItm(e) {
      var index = this.events.indexOf(e);
      if (index !== -1) {
        this.delete_task(this.events[index]);
        this.events.splice(index, 1);
      }
      this.event_dialog = false;
    },
    changeToWeek(e) {
      console.log(e);
      this.value = e.date;
      this.type = "day";
    },
    update_calendar() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "tasks",
        data: this.events,
      };
      console.log(qry);
      webserver("put_table", qry, (data) => {
        console.log(data);
        this.events = data;
      });
    },
    delete_task(e) {
      var qry = {
        account: this.$store.state.profile.account,
        table: "tasks",
        filters: [
          { field: "account", operator: "=", value: e.account },
          { field: "client", operator: "=", value: e.client },
          { field: "code", operator: "=", value: e.code },
        ],
      };
      console.log(qry);
      webserver("delete_record", qry, (data) => {
        console.log(data);
      });
    },
  },
};
</script>
<style>
.delItem {
  right: 0px !important;
}
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
</style>
