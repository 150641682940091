<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-center-transition"
    max-width="600"
  >
    <v-card tile>
      <v-card-title> Event </v-card-title>
      <v-card-text class="pt-6">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="event.event.name"
              hide-details="auto"
              label="Title"
              outlined
              required
            />
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field
              v-model="event.eventParsed.start.date"
              hide-details="auto"
              label="From"
              outlined
              required
              type="date"
              @change="change_date_start"
              dense
            />
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field
              v-model="event.eventParsed.start.time"
              hide-details="auto"
              label="From"
              outlined
              required
              type="time"
              @change="change_start"
              dense
            />
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field
              v-model="event.eventParsed.end.date"
              hide-details="auto"
              label="To"
              outlined
              required
              type="date"
              @change="change_date_end"
              dense
            />
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field
              v-model="event.eventParsed.end.time"
              hide-details="auto"
              label="To"
              outlined
              required
              type="time"
              @change="change_end"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text @click="delItem()">Delete</v-btn>
        <v-btn text @click="close()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import createEvent from "../utils/createEvent.js";
export default {
  name: "EventForm",
  components: {},
  data() {
    return {
      issueDate: null,
      start: null,
      end: null,
      f_required: [(v) => !!v || "Requerido"],
    };
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    event: {
      type: Object,
      required: true,
      default: createEvent(),
    },
  },
  methods: {
    close() {
      this.$emit("close", true);
    },
    delItem() {
      console.log(this.event.event);
      this.$emit("delItem", this.event.event);
    },
    change_start(e) {
      var diff = this.event.event.end - this.event.event.start;
      this.event.event.start = new Date(
        this.event.eventParsed.start.date + "T" + e
      ).getTime();
      this.event.event.end = this.event.event.start + diff;
    },
    change_end(e) {
      this.event.event.end = new Date(
        this.event.eventParsed.end.date + "T" + e
      ).getTime();
    },
    change_date_start(e) {
      this.event.event.start = new Date(
        e + "T" + this.event.eventParsed.start.time
      ).getTime();
    },
    change_date_end(e) {
      this.event.event.end = new Date(
        e + "T" + this.event.eventParsed.end.time
      ).getTime();
    },
  },
  watch: {
    // event: function (e) {
    //   if (e.start) {
    //     this.issueDate = new Date(e.start).toISOString().substring(0, 10);
    //     this.start = new Date(e.start).toLocaleTimeString();
    //   }
    //   if (e.end) {
    //     this.end = new Date(e.end).toLocaleTimeString();
    //   }
    // },
  },
};
</script>
