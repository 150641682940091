var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mt-6",attrs:{"elevation-10":"","width":"100%"}},[_c('v-card-title',[_c('v-select',{attrs:{"id":"category","hide-details":"auto","label":"Client","items":_vm.clients,"item-text":"name","item-value":"code","outlined":"","required":"","return-object":""},on:{"change":_vm.client_selection}}),_c('v-spacer'),_c('v-spacer')],1),(_vm.clientSelected.name)?_c('v-card-text',[_c('h3',[_vm._v(_vm._s(this.clientSelected.name))]),_vm._v(" "+_vm._s(this.clientSelected.address)+" - "+_vm._s(this.clientSelected.address2)+" , "+_vm._s(this.clientSelected.postal_code)),_c('br'),_vm._v(" "+_vm._s(this.clientSelected.mobile)+" ")]):_vm._e(),_c('v-card-actions')],1),(_vm.clientSelected.code)?_c('v-card',{staticClass:"mt-6",attrs:{"elevation-10":"","width":"100%"}},[_c('v-data-table',{attrs:{"headers":_vm.headers[_vm.$vuetify.breakpoint.xsOnly],"items":_vm.team,"items-per-page":10,"item-key":"code","sort-by":"issue_date","mobile-breakpoint":"10","loader-height":"10","loading-text":"Cargando ..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Our team")]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"#1c2e5a"},on:{"click":function($event){_vm.addMember = !_vm.addMember}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.delete_member(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1):_vm._e(),(_vm.clientSelected.code)?_c('v-sheet',{staticClass:"d-flex mt-6",attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"icon":"","color":"success"},on:{"click":function($event){_vm.editable = !_vm.editable}}},[(!_vm.editable)?_c('v-icon',[_vm._v("mdi-lock-outline")]):_c('v-icon',[_vm._v("mdi-lock-open-outline")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"icon":"","color":"success"},on:{"click":_vm.update_calendar}},[_c('v-icon',[_vm._v("mdi-content-save-outline")])],1),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'day'}}},[_c('v-list-item-title',[_vm._v("Day")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week'}}},[_c('v-list-item-title',[_vm._v("Week")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month'}}},[_c('v-list-item-title',[_vm._v("Month")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = '4day'}}},[_c('v-list-item-title',[_vm._v("4 days")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'category'}}},[_c('v-list-item-title',[_vm._v("Category")])],1)],1)],1)],1)],1):_vm._e(),(_vm.clientSelected.code)?_c('v-sheet',{attrs:{"tile":""}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","type":_vm.type,"events":_vm.events,"event-color":_vm.getEventColor,"event-ripple":false,"dense":""},on:{"click:day":_vm.changeToWeek,"mousedown:event":_vm.startDrag,"mousedown:time":_vm.startTime,"mousemove:time":_vm.mouseMove,"mouseup:time":_vm.endDrag,"click:event":_vm.showEvent},nativeOn:{"mouseleave":function($event){return _vm.cancelDrag($event)}},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
var timed = ref.timed;
var eventSummary = ref.eventSummary;
var eventParsed = ref.eventParsed;
return [_c('div',[_vm._v(" "+_vm._s(eventParsed.start.time + " - " + eventParsed.end.time)+" ")]),_c('div',{staticClass:"v-event-draggable"},[_c({ render: eventSummary },{tag:"component"})],1),(timed)?_c('div',{staticClass:"v-event-drag-bottom",on:{"mousedown":function($event){$event.stopPropagation();return _vm.extendBottom(event)}}}):_vm._e()]}}],null,false,2300646717),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1):_vm._e(),_c('v-navigation-drawer',{attrs:{"right":"","value":_vm.addMember,"width":"400px","app":""}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-app-bar',{attrs:{"color":"#1c2e5a","dark":""}},[_c('v-app-bar-title',[_vm._v("Team")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.addMember = !_vm.addMember}}},[_c('v-icon',[_vm._v("mdi-close ")])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers[true],"items":_vm.employees,"items-per-page":10,"item-key":"code","sort-by":"issue_date","mobile-breakpoint":"10","loader-height":"10","loading-text":"Cargando ..."},on:{"click:row":_vm.addTeamMember}})],1),_c('v-card-actions',{staticClass:"pl-0 pr-0"})],1)],1),_c('EventForm',{attrs:{"event":_vm.event,"dialog":_vm.event_dialog},on:{"close":function($event){_vm.update_calendar();
      _vm.event_dialog = false;},"delItem":_vm.delItm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }